#AboutPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
}

.about-flex-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

@media only screen and (max-width: 1200px) {
  .about-flex-container {
    display: flex;
    flex-direction: column;
  }
}
