@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

*,
*::before,
*::after,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #000;
  color: whitesmoke;
  font-family: 'Rubik', sans-serif;
}

a {
  text-decoration: none;
  color: whitesmoke;
}

.align-layout {
  padding-right: 6rem;
  padding-left: 6rem;
}

.align-y {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

@media only screen and (max-width: 800px) {
  .align-layout {
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .align-y {
    padding-top: 6rem;
    padding-bottom: 1rem;
  }
}
