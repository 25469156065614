@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.parallax {
  height: 100vh;
  /* background-position: top center;
  background-size: cover; */
  /* position: relative; */
}

.hero-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  box-shadow: blue 7px;

  background-position: 50% 10%;
  background: rgba(0, 0, 0, 0.15);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  margin: 0 4rem;
  padding: 1.5rem;
}

.hero > h1 {
  text-align: center;
  font-size: 6rem;
}

.hero > p {
  font-size: 1rem;
}

.hero-cta {
  margin-top: 1.5rem;
}

.btn-link_alt {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  border-radius: 2px;
}

@media only screen and (max-width: 1250px) {
  .hero > h1 {
    font-size: 4rem;
  }

  .hero > p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .hero > h1 {
    font-size: 3rem;
  }

  .hero > p {
    font-size: 0.75rem;
  }

  .btn-link_alt {
    margin-top: 1.5rem;
    font-size: 1rem;
  }
}
